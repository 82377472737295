import { SPONSORS, type Sponsor } from './sponsors.constants.ts'

export const SIGNUP_CLOSE_DATE = '2024-03-09'

export const REGISTRATION_2024 = {
	registrationOpenDate: '2024-01-19 09:00',
	registrationCloseDate: '2024-02-28',
	registrationFullyCloseDate: '2024-03-03',
	withdrawCloseDate: '2024-02-11',
	price: 150,
	totalSlots: 125,
}

export const EVENTS = [
	{
		id: 't1-april-2024',
		eventName: 'Thunderhawk',
		eventDate: '2024-04-27',
		eventDescription: 'Shotgun Start',
		eventTime: '13:00',
		eventTZ: 'America/Chicago',
		eventLocation:
			'Thunderhawk Golf Club, 39700 N Lewis Ave, Beach Park, IL 60099, United States',
		registrationOpenDate: '2024-03-30',
		registrationCloseDate: '2024-04-14',
		withdrawCloseDate: '2024-04-14',
		eventPrice: 125,
		imgSrc: '/img/events/thunderhawk.jpeg',
		totalSlots: 88,
		waitlistSlots: 12,
		sponsors: [SPONSORS[1]] as Sponsor[],
	},
	{
		id: 't2-may-2024',
		eventName: 'Cantigny',
		eventDate: '2024-05-18',
		eventTime: '13:00',
		eventTZ: 'America/Chicago',
		eventDescription: 'St. Tees (2 course)',
		eventLocation:
			'Canitgny Golf Club, 27W270 Mack Rd, Wheaton, IL 60189, United States',
		registrationOpenDate: '2024-04-20',
		registrationCloseDate: '2024-05-05',
		withdrawCloseDate: '2024-05-05',
		eventPrice: 150,
		imgSrc: '/img/events/cantigny.png',
		totalSlots: 96,
		waitlistSlots: 12,
		sponsors: [SPONSORS[3]] as Sponsor[],
	},
	{
		id: 't3-june-2024',
		eventName: 'Orchard Valley',
		eventDate: '2024-06-15',
		eventTime: '08:00',
		eventTZ: 'America/Chicago',
		eventDescription: 'Shotgun Start',
		eventLocation:
			'Orchard Valley Golf Club, 2411 W Illinois Ave, Aurora, IL 60506, United States',
		registrationOpenDate: '2024-05-19',
		registrationCloseDate: '2024-06-06',
		withdrawCloseDate: '2024-06-06',
		eventPrice: 125,
		imgSrc: '/img/events/orchard.png',
		totalSlots: 88,
		waitlistSlots: 12,
		sponsors: [SPONSORS[2]] as Sponsor[],
	},
	{
		id: 't4-july-2024',
		eventName: 'Whitetail Ridge',
		eventDate: '2024-07-20',
		eventTime: '08:00',
		eventTZ: 'America/Chicago',
		eventLocation:
			'Whitetail Ridge Golf Club, 7671 Clubhouse Dr, Yorkville, IL 60560, United States',
		eventDescription: 'Shotgun Start',
		registrationOpenDate: '2024-06-22',
		registrationCloseDate: '2024-07-11',
		withdrawCloseDate: '2024-07-11',
		eventPrice: 125,
		imgSrc: '/img/events/whitetail.jpeg',
		totalSlots: 88,
		waitlistSlots: 12,
		sponsors: [SPONSORS[5]] as Sponsor[],
	},
	{
		id: 't5-august-2024',
		eventName: 'Prairie Landing',
		eventDate: '2024-08-17',
		eventTime: '13:00',
		eventTZ: 'America/Chicago',
		eventLocation:
			'Prarie Landing Golf Club, 2325 Longest Dr, West Chicago, IL 60185, United States',
		eventDescription: 'Shotgun Start',
		registrationOpenDate: '2024-07-21',
		registrationCloseDate: '2024-08-07',
		withdrawCloseDate: '2024-08-07',
		eventPrice: 125,
		imgSrc: '/img/events/prairie.png',
		totalSlots: 95,
		waitlistSlots: 12,
		sponsors: [SPONSORS[6], SPONSORS[0]] as Sponsor[],
	},
	{
		id: 't6-september-2024',
		eventName: 'Foxford Hills',
		eventDate: '2024-09-07',
		eventTime: '13:00',
		eventTZ: 'America/Chicago',
		eventLocation:
			'Foxford Hills Golf Club, 6800 S Rawson Bridge Rd, Cary, IL 60013, United States',
		eventDescription: 'Shotgun Start',
		registrationOpenDate: '2024-08-18',
		registrationCloseDate: '2024-08-31',
		withdrawCloseDate: '2024-08-31',
		eventPrice: 125,
		imgSrc: '/img/events/foxford.png',
		totalSlots: 88,
		waitlistSlots: 12,
		sponsors: [SPONSORS[4]] as Sponsor[],
	},
	{
		id: 't7-september-2024',
		eventName: 'Broken Arrow',
		eventDate: '2024-09-28',
		eventTime: '13:00',
		eventTZ: 'America/Chicago',
		eventLocation:
			'Broken Arrow Golf Club, 16325 Broken Arrow Dr, Lockport, IL 60441, United States',
		eventDescription: 'Shotgun Start',
		registrationOpenDate: '2024-09-09',
		registrationCloseDate: '2024-09-21',
		withdrawCloseDate: '2024-09-21',
		eventPrice: 110,
		imgSrc: '/img/events/broken.webp',
		totalSlots: 88,
		waitlistSlots: 12,
		sponsors: [] as Sponsor[],
	},
]

export const GUEST_EVENT = {
	id: 'guest-event',
}
